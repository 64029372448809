.dropzone-root {
    min-width: none;
    max-width: none!important;
    border-color: rgba(0, 0, 0, 0.12)!important;
}

.dropzone-root .dmajdp{
    justify-content: center;
}

.dropzone-root svg{
    display: none;
}