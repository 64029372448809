.tableRow:hover .customButton {
  display: table-cell;
}
.customTd {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.tableRow {
  position: relative;
}
.customButton {
  display: none;
  width: auto;
  right: 25px;
  top: 10px;
  position: absolute;
  background-color: #F5F5F5;
}