@media screen and (max-width: 640px) {
    .row_table,
    .row_table td,
    .row_table th {
      border: 1px solid #cbced2;
      border-collapse: collapse;
      text-align: center;
    }
    
    .row_table th {
      background: #01214d;
      color: #fff;
      padding: 4px 12px;
      display: block;
      width: 100%
    }
    
    .row_table td {
      background: #f5f6f8;
      padding: 4px 12px;
      display: block;
      width: 100%;
    }
}